<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
     
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Search Catalog Memeber</v-card-title>
                <v-row class="px-5 pt-4">
                 <v-col cols="12" md="4" sm='4'>
                    <v-autocomplete v-model="selectedisbn" outlined label="ISBN" :items="isbnlist" item-text="name" item-value="id"></v-autocomplete>
                 </v-col>
                  <v-col cols="12" md="4" sm='4'>
                     <v-autocomplete v-model="selectedtitle" outlined label="Book Title" :items="titlelist" item-text="name" item-value="id"></v-autocomplete>
                 </v-col>
                 <v-col cols="12" md="4" sm='4'>
                     <v-autocomplete v-model="selectedauthor" outlined label="Author" :items="autherlist" item-text="name" item-value="id"></v-autocomplete>
                 </v-col>
                 </v-row>
                 <v-row class="px-5 pt-4">
                 <v-col cols="12" md="2" sm='2'></v-col>
                   <v-col cols="12" md="4" sm='4'>
                     <v-autocomplete v-model="selecteddepartment" outlined label="Department" :items="departmentlist" item-text="name" item-value="id"></v-autocomplete>
                 </v-col>
                   <v-col cols="12" md="4" sm='4'>
                     <v-autocomplete v-model="selectedpublisher" outlined label="Publisher" :items="publisherlist" item-text="name" item-value="id"></v-autocomplete>
                 </v-col>
                </v-row>
                <v-row class="px-6" justify="center" align="center">
                <v-divider></v-divider>
               <span class=""> <h4>OR</h4></span>
                <v-divider></v-divider>
                </v-row>
                   <v-row class="" justify="center" align="center">
                    <v-col cols="12" md="4" sm='4'>
                    </v-col>

                                <v-text-field class="" @input="reloadAllBook" placeholder="Search by Accession No" v-model="a_no" outlined dense></v-text-field>
                                 <v-col cols="12" md="4" sm='4'>
                    </v-col>
                                
                            </v-row>
                             <v-row  justify="center" align="center">
                             <v-btn class="m-2" color="info" @click="searchByA_no">Search</v-btn>
                             </v-row>
            <v-data-table v-if="Array.isArray(bookList1) && bookList1.length"  :expanded.sync="expanded" single-expand show-expand v-model="selected" :headers="headers" :items="bookList1" item-key="isbn" class="elevation-1 mt-5" :search="search" @current-items="getFiltered">
                <v-spacer></v-spacer>

                <template v-slot:top>
                    <v-toolbar flat color="white">
                       

                        <!-- <v-switch v-model="singleExpand" label="Single expand" class="mt-2"></v-switch>                        <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details> </v-text-field> -->
                     <!--   <download-excel class="ml-5" :data="filterArr"  :fields="fields" worksheet="bookItemlist" name="BookItem.xls">
                            <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon> </v-btn>
                        </download-excel>-->
                    </v-toolbar>
                     <br/>
                    <v-row v-if="Array.isArray(bookList1) && bookList1.length" justify="center" align="center">
                    <v-card width="250" height="100" class="ma-5 blue white--text"><br/><center>Total number of books<br/><h2>{{bookitemcount}}</h2></center></v-card>
                    <v-card width="250" height="100" class="ma-5 orange white--text"><br/><center>Total number of book titles<br/><h2>{{booktitlecount}}</h2></center></v-card>
                    </v-row>
                    <br>
                    <hr>

                    <tr class="t-row">
                        <div>
                            <th class="d-flex">
                                <span v-for="header in headers" :key="header.text">
                                    <span v-if="filters.hasOwnProperty(header.value)">
                                        <!-- <span v-if="header.text == 'ISBN' || header.text == 'Title'">
                                        <v-text-field outlined dense class="m-2" :label="header.text" v-model="filters[header.value]" placeholder=" " :disabled="!filters.hasOwnProperty(header.value)"> </v-text-field>
                                    </span>
                                    <span v-else> -->
                                        <v-autocomplete class="m-2" flat hide-details small dense outlined :label="header.text" multiple clearable :items="columnValueList(header.value)" v-model="filters[header.value]"></v-autocomplete>
                                    </span>
                                    <!-- </span> -->
                                </span>
                                <!-- <v-autocomplete class="m-2" @change="searchByAuthor" flat hide-details small dense outlined label="Authors" multiple clearable :items="authorsList" v-model="selected_author" item-text="name" item-value="id"></v-autocomplete> -->
                            </th>
                        </div>
                    </tr>
                </template>
                <!-- <template v-slot:expanded-item="{item }">
                          sneha{{item.copies}}
                      </template> -->

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" style="padding-left: 30px;">
                        <v-col cols="6">
                            <v-simple-table class="mt-3" style="border: 1px solid;">
                                <template v-slot:default>
                                    <thead>
                                        <tr style="background: lightgray;">
                                            <th class="text-left" style="font-size: 16px;">
                                                Accession Number
                                            </th>
                                            <th class="text-left" style="font-size: 16px;">
                                                Status
                                            </th>
                                             <th class="text-left" style="font-size: 16px;">
                                                Rack no
                                            </th>
                                             <th class="text-left" style="font-size: 16px;">
                                                Block no
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item1 in item.itemdata" :key="item1.name" :class="{ search_row: item1.accession_no == a_no }">
                                            <td>{{ item1.accession_no }}</td>
                                            <td>{{ item1.bookstatus }}</td>
                                            <td v-if="item1.rack">{{ item1.rack }}</td>
                                            <td v-else>-</td>
                                            <td v-if="item1.block">{{ item1.block }}</td>
                                            <td v-else>-</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </td>
                </template>

                <template v-slot:item.no_copies="{ item }">
                    <router-link class="bookItem" :to="{ name: 'reserve-book-student', params: {assno:  item.accession_no} }" style="text-decoration: none;">
                        {{item.copies}}
                    </router-link>
                </template>
                <template v-slot:item.no_available_copies="{ item }">
                    <router-link class="bookItem" :to="{ name: 'reserve-book-student', params: {assno:  item.accession_no} }" style="text-decoration: none;">
                        {{item.available}}
                    </router-link>
                </template>
            </v-data-table>
        </v-card>
        <v-overlay :value="overlay">
            Loading..
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            valid: false,
            singleExpand: false,
            expanded: [],
            overlay: false,
            selected_author: [],
            snackbar_msg: "",
            color:"",
            snackbar: false,
            pagination: {
                sortBy: "name",
            },
            dialog: true,
            selected: [],
            authorsList: [],
            a_no: null,
            headers: [
                {
                    text: "Sr No",
                    value: "srno",
                },
                { text: "ISBN", value: "isbn" },
                { text: "Title", value: "title" },
                { text: "Edition", value: "edition" },
                { text: 'Organization', value: 'organization' },
                { text: "Book Type", value: "bookType" },
                { text: "Authors", value: "authors" },
                // { text: "Category", value: "category" },
                { text: "Department", value: "department" },
                { text: "Publisher", value: "publisher" },
                { text: "No of Copies", value: "no_copies" },
                { text: "Available Copies", value: "no_available_copies" },
            ],
            

            fields: {
                "Sr No":"srno",
                 "ISBN":"isbn",
                 "Title":"title",
                 "Edition":"edition",
                 'Organization':'organization',
                 "Book Type":"bookType",
                 "Authors":"authors",
                //  "Category":"category",
                 "Department":"department",
                 "Publisher":"publisher",
                 "No of Copies":"copies",
                 "Available Copies":"available",
            }
            ,


            search: "",
            filters: {
                isbn: [],
                title: [],
                department: [],
                publisher: [],
                authors: [],
                organization: [],
            },
            bookList: [],
            filterArr: [],
            organizationname:"",
             booktitlecount:"",
            bookitemcount:"",
            publisherlist:[],
            departmentlist:[],
            autherlist:[],
            titlelist:[],
            isbnlist:[],
            selectedpublisher:"ALL",
            selecteddepartment:"ALL",
            selectedauthor:"ALL",
            selectedtitle:"ALL",
            selectedisbn:"ALL",
        }),
        computed: {
            bookList1() {
                return this.bookList.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                    });
                });
            },
        },

        mounted() {
             this.overlay = true;
                    axios
                        .post("/SearchCatalog/getBookFilter")
                        .then((res) => {
                            
                            if (res.data.msg == "200") {
                                this.overlay = false;
                                this.isbnlist = res.data.isbnlist;
                                 this.publisherlist = res.data.publisherlist;
                                  this.departmentlist = res.data.departmentlist;
                                   this.autherlist = res.data.autherlist;
                                    this.titlelist = res.data.titlelist;
                               
                            }
                        });
        },
        methods: {
            
            onlyUnique(value, index, self) {
                if(value != null && value != "" && value)
                    return self.indexOf(value) === index;
            },

            columnValueList(val) {
                var data = this.bookList.map((d) => d[val]);
                data = data.filter(this.onlyUnique);
                return data
            },

            getFiltered(e) {
                console.log(e);
                this.filterArr = e;
            },

            searchByA_no() {
                const data = {
                    a_no: this.a_no,
                };
                if (this.a_no) {
                    this.overlay = true;
                    axios
                        .post("/SearchCatalog/getBookItemData", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.overlay = false;
                                this.bookList = res.data.bookList;
                                this.expanded = res.data.bookList;
                                this.authorsList = res.data.authorsList;
                                this.organizationname = res.data.organizationid;
                                this.filters.organization = [];
                                this.filters.organization.push(this.organizationname);
                                this.booktitlecount = res.data.booktitlecount;
                                 this.bookitemcount = res.data.bookitemcount;
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            window.console.log(error);
                        })
                        .finally(() => {
                            // var overlay = false;
                        });
                } else {
                    this.onLoad();
                }
            },
            reloadAllBook() {
                if (this.a_no == "") {
                    this.onLoad();
                }
            },
            onLoad() {
                this.bookList=[];
                var params={"selectedisbn":this.selectedisbn,"selectedtitle":this.selectedtitle,"selectedauther":this.selectedauther,"selecteddepartment":this.selecteddepartment,"selectedpublisher":this.selectedpublisher,"selectedauthor":this.selectedauthor};
                this.overlay = true;
                axios
                    .post("/SearchCatalog/getAllBookwithfilter",params)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.bookList = res.data.bookList;
                            this.authorsList = res.data.authorsList;
                            this.expanded = [];
                            this.organizationname = res.data.organizationid;
                            this.filters.organization = [];
                            this.filters.organization.push(this.organizationname);
                            this.booktitlecount = res.data.booktitlecount;
                                 this.bookitemcount = res.data.bookitemcount;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // this.$refs.form.reset()
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
        },
    };
</script>
<style scoped>
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .bookItem {
        text-decoration: underline !important;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 0rem 0.5rem 0.5rem 0.5rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
    .search_row {
        background: skyblue;
        border: 2px solid black;
    }
</style>
